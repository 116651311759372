import React from "react";
import LocalHeader from "../../shared/localHeader";

export default function LocalHeaderAbout() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="localHeaderAbout">
      <LocalHeader
        titlesize="display-1"
        title={"Wir freuen\n uns auf \n dich!"}
        buttontext="Jetzt direkt bewerben"
        buttonGridTemplateAreas={`'. . text' '. grid2 .'`}
        buttonTextBackgroundColor="black"
        buttonTextColor="white"
        buttonIconColor="yellow"
        buttonInfoText=""
        link="https://utum.typeform.com/to/wmvFTyi3"
        target="_blank"
      />
    </section>
  );
}
