import Matthias from "../../../../assets/img/components/team/matthias-b.png";
import Leopold from "../../../../assets/img/components/team/leopold.webp";


let members = [
    {
        name: "Matthias",
        title: "Projektsteuerung",
        shortDescription: `matthias.brechenmacher@unternehmertum.de`,
        pic: Matthias,
        link: "https://www.linkedin.com/in/brechenmacher-m/"
    }, 
    {
        name: "Leopold",
        title: "Community Manager",
        shortDescription: `leopold.schlenk@unternehmertum.de`,
        pic: Leopold,
        link: "https://www.linkedin.com/in/dr-leopold-von-schlenk-barnsdorf-57b14114b/"
    }, 
];

export default members;