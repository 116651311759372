import React from "react";
import utum from "../../../assets/img/components/brand/utumLogo.svg";
import ministry from "../../../assets/img/components/brand/stmdLogo-large.svg";

let collaborations = [
  {
    title: "Bayerisches Staatsministerium für Digitales",
    description:
      "Um zukünftige Unternehmensnachfolgerinnen und Unternehmensnachfolger aus Bayern bei der Bewältigung ihrer  Herausforderungen zu unterstützen, hat das bayerische Staatsministerium für Digitales die Initiative NextGen4Bavaria ins Leben gerufen.",
    alt: "staatsministerium für digitales",
    image: ministry,
    link: "https://www.stmd.bayern.de",
  },
  {
    title: "UnternehmerTUM",
    description:
      "Europas größtes Zentrum für Innovation und Entrepreneurship setzt dieses Format um. UnternehmerTUM Business Creators unterstützt mit einem exklusiven Digitalisierungsnetzwerk und Innovationsökosystem.",
    alt: "unternehmertum",
    image: utum,
    link: "https://www.unternehmertum.de",
  },
];

export default function Collaboration() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="header">
      <div className="content grid p-grid-standard xs:grid-gap-20 md:grid-gap-50  lg:grid-gap-100 body-1">
        {collaborations.map((collaboration, index) => (
          <div className="col-12 lg:col-6" key={index}>
            <img
              src={collaboration.image}
              className="pb-20 img-h-60"
              alt={collaboration.alt}
            />
            <br />
            <p className="fw-semi-bold pb-10">{collaboration.title}</p>
            <p className="pb-20">
              {collaboration.description}{" "}
              <a
                href={collaboration.link}
                target="_blank"
                rel="noreferrer"
                className="tc-blue fw-semi-bold"
              >
                mehr
              </a>
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
