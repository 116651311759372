import React from "react";
import ColumnText from "../../shared/columnText";

export default function Introduction() {
  return (
    <section data-aos="fade-up" data-aos-duration="3000" id="introduction">
      <ColumnText
        nrColumns="2"
        textLeftStyle="headline-1"
        textLeft="Wir erkennen Deine Bedürfnisse und begleiten Dich dabei Deine Herausforderungen zu lösen!"
        textRight={
          <>
            <p>
            Das Projektteam von NextGen4Bavaria umfasst eine Gruppe von Expertinnen und Experten aus den Bereichen Projektmanagement, Design, Marketing, New Work, Agile Coaching, etc.
            </p>
            <p className="pt-20">
            Unsere Expertinnen und Experten verfügen über langjährige Erfahrung in Startups, Industrie und Forschung. Sie kennen die Bedürfnisse mittelständischer Unternehmen und gehen gezielt auf die Herausforderungen im Unternehmen ein.
            </p>
            <p className="pt-20">
            So unterstützen sie Euch dabei, Euer Unternehmen fit für die datengetriebene und vernetzte Welt zu machen!
            </p>
          </>
        }
      />
    </section>
  );
}
